import React from 'react'
import BaseComponent from './BaseComponent'
import { NavLink } from 'react-router-dom'
import Modal from './Modal'

export default class extends BaseComponent {

	constructor(props) {
		super(props)
		this.state = { sticky: "", anim: "", popup: false }
	}

  componentDidMount() {
  	document.addEventListener('scroll', (e) => {
      if (document.body.scrollTop > 10 || document.documentElement.scrollTop > 10) {
          this.setState({ sticky: "sticky" })
      } else {
          this.setState({ sticky: "" })
      }
    })

    setTimeout(() => { this.setState({ anim: "anim" }) }, 400)
  }

  render() {
  	let nav, hidden
  	this.props.nav ? nav = "nav-open" : nav = "nav-closed"

    this.props.location.pathname === "/heatmapa" ? hidden = "heatmap-open" : hidden = ""

    const Popup = (
      <div className="confirm select-page-popup">
        <h4>Wybierz stronę:</h4>
        <ul>
          { this.props.domains.map((item, i) => {
            return (
              <li key={ i } onClick={ () => { this.props.selectDomain(item); this.setState({ popup: false }) } }>{ item.name }</li>
            )
          }) }
        </ul>
      </div>
    )

    return (
      <header className={ this.state.sticky + " " + this.state.anim + " " + nav + " " + hidden }>
        <div className="header-inner">
        	<div className="burger" onClick={ this.props.toggleNav }><div className="line"></div></div>
          <div className="selected-domain" onClick={ () => { this.setState({ popup: true }) } }>
            <p>{ this.props.selectedDomain.name }</p>
          </div>
        	<div className="user">
            <NavLink to={ "/user" }>
          		<span className="name">{ this.props.user ? this.props.user.name : null }</span>
            </NavLink>
            <NavLink to={ "/user" }>
          		<div className="avatar" style={{ backgroundImage: "url(/assets/img/user-icon.png)" }}></div>
            </NavLink>
        		<button className="btn btn-logout" onClick={ this.props.logout }><span>Wyloguj&nbsp;&nbsp;></span></button>
        	</div>
        </div>
        <Modal open={ this.state.popup } hidePopup={ () => { this.setState({ popup: false }) } }>{ Popup }</Modal>
      </header>
    )
  }
}
