import React from 'react'
import BaseComponent from './BaseComponent'
import $ from 'jquery'
import 'parsleyjs'
import { SketchPicker } from 'react-color'
import Dropzone from './Dropzone'
import Gallery from './Gallery'
import Roles from './Roles'

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { options: [], id: 1, preloader: true, repeat: "", passerr: false }
    this._bind('handleInputChange', 'handleEditorChange', 'onImageChange', 'add', 'save', 'onRoleChange', 'handlePassChange')
  }

  componentWillMount() {
    this.props.data.fields.map((item, i) => {
      this.setState({
        [item.name]: ""
      })

      return null
    })
  }

  componentDidMount() {
    fetch(this.props.API+"/user/session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": "Bearer " + sessionStorage.getItem('token')
      }
    })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      let single = result.data.user
      this.props.data.fields.map((item, i) => {
        if (single[item.name]) {
          this.setState({
            [item.name]: single[item.name]
          })
        }

        return null
      })
      this.setState({ id: single.id, preloader: false })
    }, (error) => {
      console.log(error)
    })
  }

  handleInputChange(event, editor) {
    const target = event.target
    let value
    if(target.type === 'checkbox') {
      value = target.checked ? "1" : "0"
    } else {
      value = target.value
    }
    let name = target.name

    this.setState({
      [name]: value
    })
  }

  handleEditorChange(name, event, editor) {
    let value = editor.getContent()

    this.setState({
      [name]: value
    })
  }

  onRoleChange(e) {
    const target = e.target
    let value = []
    value.push({ id: e.target.value })
    let name = target.name

    this.setState({
      [name]: value
    })
  }

  onImageChange(name, image) {
    let value = image

    this.setState({
      [name]: value
    })
  }

  handlePassChange(event) {
    const target = event.target
    let value = target.value
    let name = target.name

    this.setState({
      [name]: value
    })
  }

  add(e) {
    e.preventDefault()

    if (this.state.password !== this.state.repeat) {
      this.setState({ passerr: true })
    } else {
      this.setState({ passerr: false })
    }

    if($('.fields').parsley().validate() && (this.state.password === this.state.repeat)) {
      this.save()
    }
  }

  save() {
    this.setState({ preloader: true })
    let params = {
      user: this.state
    }
    fetch(this.props.API+"/user/update", {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": "Bearer " + sessionStorage.getItem('token')
      },
      body: JSON.stringify(params)
    })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      if (!result.status.success) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.error}}), true);
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Pomyślnie zapisano zmiany."}}), true);
        this.setState({ password: "", repeat: "", changePassword: "0" })
      }
      this.setState({ preloader: false })
    }, (error) => {
      console.log(error)
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Coś poszło nie tak."}}), true);
    })
  }

  render () {

    let fields = this.props.data.fields.map((item, i) => {

      if (item.dependOn) {
        if (!(this.state[item.dependOn.name] === item.dependOn.value)) {
          return
        }
      }

      if (item.type === "text") {
        return (
          <div key={ i }>
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            <input type="text" name={ item.name } value={ this.state[item.name] } onChange={ this.handleInputChange } required={ item.required ? true : false }/>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          </div>
        )
      } else if(item.type === "option") {
        let options = this.state.options.map((itemj, j) => {
          return (
            <option key={ j } value={ itemj.id } required={ item.required ? true : false }>{ itemj.title }</option>
          )
        })

        return (
          <div key={ i }>
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            <select name={ item.name } onChange={ this.handleInputChange } value={ this.state[item.name] }>
              <option value="0">Wybierz</option>
              { options }
            </select>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          </div>
        )
      } else if(item.type === "textarea") {
        return (
          <div key={ i }>
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            <textarea name={ item.name } value={ this.state[item.name] } onChange={ this.handleInputChange } required={ item.required ? true : false }></textarea>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          </div>
        )
      } else if(item.type === "color") {
        return (
          <div key={ i }>
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            <SketchPicker id={ item.name } color={ this.state[item.name] } onChangeComplete={ (e) => { this.setState({ [item.name]: e.hex }) } }/>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          </div>
        )
      } else if(item.type === "image") {
        let img = ""

        if (this.state[item.name] !== "") {
          img = this.state[item.name]
        }

        return (
          <div key={ i }>
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            <Dropzone image={ img } name={ item.name } onImageChange={ this.onImageChange } sessionId={ this.props.sessionId }/>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          </div>
        )
      } else if(item.type === "gallery") {
        let img = []

        if (this.state[item.name] !== "") {
          img = this.state[item.name]
        }
        return (
          <div key={ i }>
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            <Gallery image={ img } name={ item.name } onImageChange={ this.onImageChange } sessionId={ this.props.sessionId }/>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          </div>
        )
      } else if (item.type === "checkbox") {
        return (
          <div key={ i } className="checkbox">
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
            <input type="checkbox" id={ i } name={ item.name } value={ this.state[item.name] } onChange={ this.handleInputChange } checked={ this.state[item.name] === "1" ? true : false }/>
            <label htmlFor={ i }></label>
          </div>
        )
      } else if (item.type === "passwd") {
        return (
          <div key={ i } className="title">
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
            <input type="password" name={ item.name } value={ this.state[item.name] } onChange={ this.handlePassChange } data-parsley-pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$" required={ item.required ? true : false }/>
            <p className="input-desc">Powtórz hasło</p>
            <input type="password" name="repeat" value={ this.state.repeat } onChange={ this.handlePassChange } data-parsley-pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$" required={ item.required ? true : false }/>
            { this.state.passerr ? <p className="error">Hasła są różne!</p> : null }
          </div>
        )
      }
    })

    return (
      <section className="new">
        <div className="container">
          <h1>Ustawienia użytkownika</h1>
          <form className="fields" data-parsley-validate>
            <div className="add-top" onClick={ this.add }>Zapisz zmiany</div>
            { fields }
            <div className="add-bottom" onClick={ this.add }>Zapisz zmiany</div>
            { this.state.preloader ? <div className="preloader"><span></span></div> : null }
          </form>
        </div>
      </section>
    )
  }
}
