import React from 'react'
import BaseComponent from './BaseComponent'
import Todos from './Todos'

export default class extends BaseComponent {

  render() {
    return (
      <section className="home">
        <div className="container">
        	<h1>Witaj, { this.props.user.name }</h1>
        	{ /*<Todos API={ this.props.API }/> */ }
        </div>
      </section>
    )
  }
}
