import React from 'react'
import BaseComponent from './BaseComponent'

export default class extends BaseComponent {

  constructor(props) {
  	super(props)
  	this.state = {
      roles: []
  	}
  	this._bind('getRoles')
  }

  componentWillMount() {
  	this.getRoles()
  }

  getRoles() {
  	fetch(this.props.API+"/api/admin/data/list/role", {
      method: "POST",
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + sessionStorage.getItem('token')
        }
      })
  	.then((response) => {
  	  return response.json()
  	})
  	.then((result) => {
  		let res = result.data.result
  		if (res === null) {
  		  res = []
  		}

  		this.setState({ roles: res, preloader: false })
  	}, (error) => {
  		console.log(error)
  		this.setState({ preloader: false })
  	})
  }

  render () {
    let value = this.props.value ? this.props.value[0].id : ""

    const roles = this.state.roles.map((item, i) => {
      if (item.title !== "root" && item.title !== "Anonymous") {
        return (
          <option key={ i } value={ item.id }>{ item.title }</option>
        )
      }
    })

    return (
    	<select name={ this.props.name } onChange={ this.props.onRoleChange } value={ value } disabled={ this.props.user.role === "admin" ? false : true } required>
        <option value="">Wybierz uprawnienia</option>
        { roles }
      </select>
    )
  }
}
