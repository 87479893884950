import React from 'react'
import BaseComponent from './BaseComponent'
import Tags from 'react-tag-autocomplete'

export default class extends BaseComponent {

  constructor(props) {
  	super(props)
  	this.state = {
  	  preloader: true,
  	  tags: [],
      suggestions: []
  	}
  	this._bind('getSuggestions', "addSuggestion", 'handleDelete', 'handleAddition')
  }

  componentWillMount() {
  	this.getSuggestions()
  }

  getSuggestions() {
    let params = { "start" : 0, "stop" : 999 }
  	fetch(this.props.API+"/api/admin/data/list/product", {
      method: "POST",
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + sessionStorage.getItem('token')
        },
        body: JSON.stringify(params)
      })
  	.then((response) => {
  	  return response.json()
  	})
  	.then((result) => {
  		let res = result.data.result
  		if (res === null) {
  		  res = []
  		}

  		this.setState({ suggestions: res, preloader: false })
  	}, (error) => {
  		console.log(error)
  		this.setState({ preloader: false })
  	})
  }

  handleDelete (i) {
    const tags = this.props.tags.slice(0)
    tags.splice(i, 1)
    this.props.onTagsChange(tags)
  }

  handleAddition (tag) {
    const tags = [].concat(this.props.tags, tag)
    // let count = _.filter(this.state.suggestions, { name: tag.name }).length
    // if (count === 0) {
    // 	this.addSuggestion(tag)
    // }
    this.props.onTagsChange(tags)
  }

  addSuggestion(tag) {
  	let params = { name: tag.name }
    let data  = {"type" : "addTags", "params" : params, "session_id" : this.props.sessionId}
    fetch("/api/app.php", {
      method: "POST",
      body: JSON.stringify(data)
    })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      if (result.error) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.error}}), true);
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Pomyślnie dodano."}}), true);
      }
      this.getSuggestions()
    }, (error) => {
      console.log(error)
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Coś poszło nie tak."}}), true);
      this.setState({ preloader: false })
    })
  }

  render () {

    return (
	      	<Tags
	      	placeholder={ "Wpisz nazwę produktu aby dodać" }
	      	allowNew={ false } 
          autofocus={ false }
	        tags={this.props.tags}
	        onChange={ () => {} }
	        suggestions={this.state.suggestions}
	        handleDelete={this.handleDelete.bind(this)}
	        handleAddition={this.handleAddition.bind(this)} />
    )
  }
}
