import React from 'react'
import BaseComponent from './BaseComponent'
import Modal from './Modal'
import Files from './Files'
import XLSX from 'xlsx'

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { confirm: false, preloader: false, ident: "" }
    this._bind('getFile', 'onFileChange', 'validateFile', 'showConfirm', 'closeConfirm', 'sendFile')
  }

  getFile() {
    this.setState({ preloader: true })
    let url = this.props.API+"/edit/map/import/columns"
    let params = {
      "domain": {
        "uuid": this.props.selectedDomain.uuid
      }
    }
    fetch(url, {
      method: "POST",
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + sessionStorage.getItem('token')
        },
        body: JSON.stringify(params)
      })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      const ws = XLSX.utils.aoa_to_sheet([result.data]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Places template");
      XLSX.writeFile(wb, "places.xlsx")
      this.setState({ preloader: false })
    }, (error) => {
      console.log(error)
    })
  }

  onFileChange(name, file) {
    this.setState({
      file: file
    })
    this.validateFile(file)
  }

  validateFile(file) {
    this.setState({ preloader: true })
    let formData = new FormData()
    formData.append('files[0]', file[0], file[0].name);
  
    let params = {
      domain: {
        uuid: this.props.selectedDomain.uuid
      }
    }

    formData.append('_input', JSON.stringify(params));
    let data = formData;

    let url = this.props.API+"/edit/map/import/load"
    fetch(url, {
      method: "POST",
      headers: {
          "Authorization": "Bearer " + sessionStorage.getItem('token')
      },
      body: data
    })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      if (result.error) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.error.message}}), true);
      } else if (result.status.success) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Plik poprawny"}}), true);
        this.showConfirm()
        this.setState({ ident: result.data.ident })
      }
      this.setState({ preloader: false })
    }, (error) => {
      console.log(error)
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Coś poszło nie tak."}}), true);
      this.setState({ preloader: false })
    })
  }

  sendFile() {
    let params = {
      domain: {
        uuid: this.props.selectedDomain.uuid
      },
      "import": {
        "uuid": this.state.ident
      }
    }
    let url = this.props.API+"/edit/map/import/save"
    fetch(url, {
      method: "POST",
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + sessionStorage.getItem('token')
      },
      body: JSON.stringify(params)
    })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      if (result.error || (result.status && !result.status.success)) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.error ? result.error.message : result.data}}), true);
      } else if (result.status.success) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Pomyślnie dodano."}}), true);
      }
      this.closeConfirm()
    }, (error) => {
      console.log(error)
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Coś poszło nie tak."}}), true);
      this.setState({ preloader: false })
    })
  }

  showConfirm() {
    this.setState({ confirm: true })
  }

  closeConfirm() {
    this.setState({ confirm: false, file: null, ident: "" })
  }

  render () {

    const Confirm = (
      <div className="confirm">
        <h4>Czy na pewno chcesz zaimportować ten plik?</h4>
        <div className="btn-holder">
          <a className="btn" onClick={ this.closeConfirm }><span>Nie</span></a>
          <a className="btn" onClick={ this.sendFile }><span>Tak</span></a>
        </div>
      </div>
    )

    let fields = this.props.data.fields.map((item, i) => {
      if (item.type === "getFile") {
        return (
          <div key={ i } className="title">
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
            <a className="btn" onClick={ this.getFile }><span>Pobierz</span></a>
          </div>
        )
      } else if (item.type === "sendFile") {
        return (
          <div key={ i }>
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
            <Files file={ this.state.file } name={ "file" } onFileChange={ this.onFileChange }/>
          </div>
        )
      }
    })

    return (
      <section className="new">
        <div className="container">
          <h1>Import</h1>
          <form className="fields" data-parsley-validate>
            { fields }
            { this.state.preloader ? <div className="preloader"><span></span></div> : null }
          </form>
        </div>
        <Modal open={ this.state.confirm } hidePopup={ this.closeConfirm }>{ Confirm }</Modal>
      </section>
    )
  }
}
