import React from 'react'
import BaseComponent from './BaseComponent'
import { NavLink } from 'react-router-dom'
import Modal from './Modal'
import _ from 'lodash'

export default class extends BaseComponent {

  constructor(props) {
  	super(props)
  	this.state = { anim: "", popup: false, expand: [] }
  }

  componentDidMount() {
  	setTimeout(() => { this.setState({ anim: "anim" }) }, 400)
  }

  triggerWebhook() {
    const params = {}
    let url = this.props.API+"/webhook/build"
      fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + sessionStorage.getItem('token')
        },
        body: JSON.stringify(params)
      })
      .then((response) => {
          return response.json()
      })
      .then((result) => {
        if (!result.status.success) {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Wystąpił błąd."}}), true);
        } else {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Pomyślnie opublikowano."}}), true);
        }
      }, (error) => {
        console.log(error)
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Coś poszło nie tak."}}), true);
        this.setState({ preloader: false })
    })
  }

  render() {
  	let nav
  	this.props.nav ? nav = "side-nav open" : nav = "side-nav"

    let navItems = this.props.data.nav.map((item, i) => {
      if (item.role !== undefined) {
        if (this.props.user.role === item.role && !item.hasParent) {
          if (item.hasChildren) {
            let items = _.filter(this.props.data.nav, { hasParent: item.name }) || []
            return (
              <li key={ i }>
                <span className={ this.state.expand[i] ? "expand open" : "expand" } onClick={ () => { if (this.state.expand[i]) { let expand = []; expand[i] = false; this.setState({ expand: expand }) } else { let expand = []; expand[i] = true; this.setState({ expand: expand }) } } }><div className="img"><img src={ item.icon } alt=""/></div>{ item.name }</span>
                { item.hasChildren && items.length > 0 && this.state.expand[i] ? <ul className="nav-items">
                  { items.map((navItem, j) => {
                    return (
                      <li key={ i + "-" + j }><NavLink to={ navItem.route } exact={ navItem.route === "/" ? true : false } onClick={ this.props.hideNav }><div className="img"><img src={ navItem.icon } alt=""/></div>{ navItem.name }</NavLink></li>
                    )
                  }) }
                </ul> : null }
              </li>
            )
          } else {
            if(item.domain) {
              if(item.domain === this.props.selectedDomain.slug) {
                return (
                  <li key={ i }><NavLink to={ item.route } exact={ item.route === "/" ? true : false } onClick={ this.props.hideNav }><div className="img"><img src={ item.icon } alt=""/></div>{ item.name }</NavLink></li>
                )
              }
            } else {
              return (
                <li key={ i }><NavLink to={ item.route } exact={ item.route === "/" ? true : false } onClick={ this.props.hideNav }><div className="img"><img src={ item.icon } alt=""/></div>{ item.name }</NavLink></li>
              )
            }
          }
        }
      } else {
        if (item.hasChildren) {
          let items = _.filter(this.props.data.nav, { hasParent: item.name }) || []
          return (
            <li key={ i }>
              <span className={ this.state.expand[i] ? "expand open" : "expand" } onClick={ this.props.hideNav }><div className="img"><img src={ item.icon } alt=""/></div>{ item.name }</span>
              { item.hasChildren && items.length > 0 && this.state.expand[i] ? <ul className="nav-items">
                { items.map((navItem, j) => {
                  return (
                    <li key={ i + "-" + j }><NavLink to={ navItem.route } exact={ navItem.route === "/" ? true : false } onClick={ this.props.hideNav }><div className="img"><img src={ navItem.icon } alt=""/></div>{ navItem.name }</NavLink></li>
                  )
                }) }
              </ul> : null }
            </li>
          )
        } else {
          if(item.domain) {
            if(item.domain === this.props.selectedDomain.slug) {
              return (
                <li key={ i }><NavLink to={ item.route } exact={ item.route === "/" ? true : false } onClick={ this.props.hideNav }><div className="img"><img src={ item.icon } alt=""/></div>{ item.name }</NavLink></li>
              )
            }
          } else {
            return (
              <li key={ i }><NavLink to={ item.route } exact={ item.route === "/" ? true : false } onClick={ this.props.hideNav }><div className="img"><img src={ item.icon } alt=""/></div>{ item.name }</NavLink></li>
            )
          }
        }
      }
    })

    const Popup = (
      <div className="confirm select-page-popup">
        <h4>Wybierz stronę:</h4>
        <ul>
          { this.props.domains.map((item, i) => {
            return (
              <li key={ i } onClick={ () => { this.props.selectDomain(item); this.setState({ popup: false }) } }>{ item.name }</li>
            )
          }) }
        </ul>
      </div>
    )

    return (
      <div className={ nav + " " + this.state.anim }>
	     	<div className="close" onClick={ this.props.hideNav }></div>
	    	<div className="top">
	    		<img className="side-logo" src="/assets/img/bat-logo-white.svg" alt=""/>
	    	</div>
        <div className="selected-page">
          <p>{ this.props.selectedDomain.name } <span onClick={ () => { this.setState({ popup: true }) } }>Zmień</span></p>
        </div>
        <nav>
          <ul className="nav-items">
            { navItems }
          </ul>
        </nav>
        { this.props.selectedDomain.slug === "alternatywnesposoby-pl" && this.props.nav ? <button className="btn" style={{ marginLeft: "25px", borderColor: "#fff" }} onClick={ () => { this.triggerWebhook() } }><span>Publikuj</span></button> : null }
	    	<div className="tdy-logo"><a href="https://tdy.pl" target="_blank"><img src="/assets/img/logo.png" alt="Today. Logo"/></a></div>
        <Modal open={ this.state.popup } hidePopup={ () => { this.setState({ popup: false }) } }>{ Popup }</Modal>
      </div>
    )
  }
}
