import React from 'react'
import BaseComponent from './BaseComponent'
import { NavLink } from 'react-router-dom'
import { List, arrayMove } from "react-movable";
import _ from 'lodash'
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-build-classic-extended';
import Files from './ModuleFiles'
import SingleFileUpload from './SingleFileUpload'

let lockUpdate = false

const RemovableIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#555"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-x-circle"
  >
    <title>Usuń</title>
    <circle cx="12" cy="12" r="10" />
    <line x1="15" y1="9" x2="9" y2="15" />
    <line x1="9" y1="9" x2="15" y2="15" />
  </svg>
);

const HandleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#555"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-move"
  >
    <polyline points="5 9 2 12 5 15" />
    <polyline points="9 5 12 2 15 5" />
    <polyline points="15 19 12 22 9 19" />
    <polyline points="19 9 22 12 19 15" />
    <line x1="2" y1="12" x2="22" y2="12" />
    <line x1="12" y1="2" x2="12" y2="22" />
  </svg>
);

const buttonStyles = {
  position: 'absolute',
  top: '20px',
  right: '20px',
  border: 'none',
  margin: 0,
  padding: 0,
  width: 'auto',
  overflow: 'visible',
  cursor: 'pointer',
  background: 'transparent'
};

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { files: [] }
    this._bind('updateItem', 'onFileChange')
  }

  updateItem(index, type, value) {
    let items = [...this.props.items]
    if((index || index === 0)  && type && !lockUpdate) {
      items[index][type] = value
      this.props.changeModuleItems(items)
    }
  }

  onFileChange(file, name, index) {
    let files = this.state.files
    if (_.filter(files, { name: name }).length < 1) {
      files.push(file)
    }
    this.setState({ files: files })
    this.updateItem(index, "fileName", [name])
    this.props.onFilesChange(files)
    //console.log(file, name)
  }

  render () {
    //console.log(this.state)
    return (
      <div className="modules">
        <List
          values={this.props.items || []}
          onChange={({ oldIndex, newIndex }) => {
              lockUpdate = true
              this.props.changeModuleItems(arrayMove(_.cloneDeep(this.props.items), oldIndex, newIndex))
              setTimeout(() => {
                lockUpdate = false
              }, 300)
            }
          }
          renderList={({ children, props }) => <ul {...props}>{children}</ul> }
          renderItem={({ value, props, isDragged, isSelected }) => { 
            let component = <input type="text" value={ value.html } onChange={ (e) => { this.updateItem(props.key, "html", e.target.value) } }/>
            if (value.type === "html") {
              component = <CKEditor
                    editor={ ClassicEditor }
                    config={ {
                      toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'subscript', 'superscript', '|', 'undo', 'redo' ]
                    } }
                    data={ value.html }
                    onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
                        // console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        //console.log( { event, editor, data } );
                        this.updateItem(props.key, "html", data)
                    } }
                    onBlur={ ( event, editor ) => {
                        //console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        //console.log( 'Focus.', editor );
                    } }
                />
            } else if (value.type === "separator") {
              component = <CKEditor
                    editor={ ClassicEditor }
                    config={ {
                      toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'subscript', 'superscript', '|', 'undo', 'redo' ]
                    } }
                    data={ value.html }
                    onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
                        // console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        //console.log( { event, editor, data } );
                        this.updateItem(props.key, "html", data)
                    } }
                    onBlur={ ( event, editor ) => {
                        //console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        //console.log( 'Focus.', editor );
                    } }
                />
            } else if (value.type === "popularPost") {
              component = <p>Tu będzie wyświetlony popularny post</p>
            } else if (value.type === "tags") {
              let val = value
              if (!val.custom) {
                val.custom = {}
              }

              component = <div>
                  <h4>Tagi</h4>
                  <p className="input-desc">Wpisz tagi po przecinku</p>
                  <input type="text" value={ val.custom.tags } onChange={ (e) => { val.custom.tags = e.target.value; this.updateItem(props.key, "custom", val.custom) } }/>
                </div>
            } else if (value.type === "html_image") {
              let val = value
              if (!val.custom) {
                val.custom = {}
              }

              component = <div>
                  <h4>Zawartość (HTML)</h4>
                  <CKEditor
                      editor={ ClassicEditor }
                      config={ {

                        toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'subscript', 'superscript', '|', 'undo', 'redo' ]
                      } }
                      data={ value.html }
                      onInit={ editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log( 'Editor is ready to use!', editor );
                      } }
                      onChange={ ( event, editor ) => {
                          const data = editor.getData();
                          //console.log( { event, editor, data } );
                          this.updateItem(props.key, "html", data)
                      } }
                      onBlur={ ( event, editor ) => {
                          //console.log( 'Blur.', editor );
                      } }
                      onFocus={ ( event, editor ) => {
                          //console.log( 'Focus.', editor );
                      } }
                  />
                  <h4>Grafika</h4>
                  <SingleFileUpload selectedDomain={ this.props.selectedDomain } API={ this.props.API } image={ val.custom.image } onChange={ (img) => { val.custom.image = img; this.updateItem(props.key, "custom", val.custom) } }/>
                  <p className="input-desc">Tekst alternatywny</p>
                  <input type="text" value={ val.custom.altImage } onChange={ (e) => { val.custom.altImage = e.target.value; this.updateItem(props.key, "custom", val.custom) } }/>
                </div>
            } else if (value.type === "image") {
              let val = value
              if (!val.custom) {
                val.custom = {}
              }

              component = <div>
                  <h4>Grafika</h4>
                  <SingleFileUpload selectedDomain={ this.props.selectedDomain } API={ this.props.API } image={ val.custom.image } onChange={ (img) => { val.custom.image = img; this.updateItem(props.key, "custom", val.custom) } }/>
                  <p className="input-desc">Tekst alternatywny</p>
                  <input type="text" value={ val.custom.altImage } onChange={ (e) => { val.custom.altImage = e.target.value; this.updateItem(props.key, "custom", val.custom) } }/>
                </div>
            } else if (value.type === "faq") {
              let val = value
              if (!val.custom) {
                val.custom = {}
              }

              component = <div>
                  <p className="input-desc">Wpisz pytanie</p>
                  <input type="text" value={ val.custom.question } onChange={ (e) => { val.custom.question = e.target.value; this.updateItem(props.key, "custom", val.custom) } }/>
                  <p className="input-desc">Wpisz odpowiedź</p>
                  <CKEditor
                      editor={ ClassicEditor }
                      config={ {

                        toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'subscript', 'superscript', '|', 'undo', 'redo' ]
                      } }
                      data={ val.custom.answer }
                      onInit={ editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log( 'Editor is ready to use!', editor );
                      } }
                      onChange={ ( event, editor ) => {
                          const data = editor.getData();
                          //console.log( { event, editor, data } );
                          val.custom.answer = data; 
                          this.updateItem(props.key, "custom", val.custom)
                      } }
                      onBlur={ ( event, editor ) => {
                          //console.log( 'Blur.', editor );
                      } }
                      onFocus={ ( event, editor ) => {
                          //console.log( 'Focus.', editor );
                      } }
                  />
                   {/* <input type="text" value={ val.custom.answer } onChange={ (e) => { val.custom.answer = e.target.value; this.updateItem(props.key, "custom", val.custom) } }/> */ }
                </div>
            } else if (value.type === "slider") {
              let val = value
              if (!val.custom) {
                val.custom = {}
              }

              component = <div>
                  <h4>Treść</h4>
                  <CKEditor
                      editor={ ClassicEditor }
                      config={ {

                        toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'subscript', 'superscript', '|', 'undo', 'redo' ]
                      } }
                      data={ value.html }
                      onInit={ editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log( 'Editor is ready to use!', editor );
                      } }
                      onChange={ ( event, editor ) => {
                          const data = editor.getData();
                          //console.log( { event, editor, data } );
                          this.updateItem(props.key, "html", data)
                      } }
                      onBlur={ ( event, editor ) => {
                          //console.log( 'Blur.', editor );
                      } }
                      onFocus={ ( event, editor ) => {
                          //console.log( 'Focus.', editor );
                      } }
                  />
                  <h4>Zdjęcie tła</h4>
                  <SingleFileUpload selectedDomain={ this.props.selectedDomain } API={ this.props.API } image={ val.custom.bgImage } onChange={ (img) => { val.custom.bgImage = img; this.updateItem(props.key, "custom", val.custom) } }/>
                  <h4>Zdjęcie tła (mobile)</h4>
                  <SingleFileUpload selectedDomain={ this.props.selectedDomain } API={ this.props.API } image={ val.custom.bgMobileImage } onChange={ (img) => { val.custom.bgMobileImage = img; this.updateItem(props.key, "custom", val.custom) } }/>
                  <h4>CSS className</h4>
                  <input type="text" value={ val.custom.className } onChange={ (e) => { val.custom.className = e.target.value; this.updateItem(props.key, "custom", val.custom) } }/>
                  <h4>BTN text</h4>
                  <input type="text" value={ val.custom.btnTitle } onChange={ (e) => { val.custom.btnTitle = e.target.value; this.updateItem(props.key, "custom", val.custom) } }/>
                  <h4>BTN url</h4>
                  <input type="text" value={ val.custom.btnUrl } onChange={ (e) => { val.custom.btnUrl = e.target.value; this.updateItem(props.key, "custom", val.custom) } }/>
                </div>
            }
            return <li {...props} style={{
              ...props.style,
              padding: '1.5em',
              margin: '0.5em 0em',
              listStyleType: 'none',
              cursor: isDragged ? 'grabbing' : 'grab',
              border: '2px solid #CCC',
              color: '#333',
              borderRadius: '5px',
              fontFamily: 'Arial, "Helvetica Neue", Helvetica, sans-serif',
              backgroundColor: isDragged || isSelected ? '#EEE' : '#FFF',
              display: value.trash ? "none" : "block"
            }} onKeyDown={ null }>
              <h4>Typ modułu</h4>
              <p className="input-desc">Wybierz typ modułu</p>
              <select value={ value.type } onChange={ (e) => { this.updateItem(props.key, "type", e.target.value) } }>
                <option value="h1">h1</option>
                <option value="p">p</option>
                <option value="html">html</option>
                <option value="html_image">html_image</option>
                <option value="image">image</option>
                <option value="faq">faq</option>
                <option value="slider">slider</option>
                <option value="separator">separator z tekstem</option>
                <option value="popularPost">popularne posty</option>
                <option value="tags">tagi</option>
              </select>
              <h4>Zawartość modułu</h4>
              <p className="input-desc">Uzupełnij zawartość modułu</p>
              { component }
              <button
                onClick={(e) => {
                  e.preventDefault()
                  this.updateItem(props.key, "trash", true)
                }}
                style={buttonStyles}
              >
                <RemovableIcon />
              </button>
              <button
                data-movable-handle
                style={{
                  ...buttonStyles,
                  cursor: isDragged ? 'grabbing' : 'grab',
                  marginRight: '3em'
                }}
                tabIndex={-1}
              >
                <HandleIcon />
              </button>
            </li> }
          }
        />
        <button className="btn" onClick={ (e) => { e.preventDefault(); this.props.addModule() } }><span>Dodaj moduł</span></button>
      </div>
    )
  }
}
