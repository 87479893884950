import React from 'react'
import BaseComponent from './BaseComponent'
import Modal from './Modal'

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { login: "", pass: "", otp: "", loginError: false, passError: false, error: false, anim: false, popup: false }
    this._bind('handleInputChange', 'checkLogin', 'processLogin', 'onPressEnter')
  }

  componentDidMount() {
  	document.addEventListener("keyup", this.onPressEnter )
    setTimeout(() => { this.setState({ anim: true }) }, 200)

    document.getElementById('login').focus()
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.onPressEnter )
  }

  onPressEnter(event) {
    event.preventDefault()
    if (event.keyCode === 13) {
      document.getElementById("button").click()
    }
  }

  handleInputChange(event) {
	const target = event.target
	let value = target.type === 'checkbox' ? target.checked : target.value
	let name = target.name

	this.setState({
	  [name]: value
	})

	if (name === "login" && name !== "") {
		this.setState({ loginError: false })
	}

	if (name === "pass" && name !== "") {
		this.setState({ passError: false })
	}
  }

  checkLogin() {
  	if (this.state.login === "") {
  		document.getElementById('login').focus()
  		this.setState({ loginError: true })
  	}

  	if (this.state.login !== "" && this.state.pass === "") {
  		document.getElementById('pass').focus()
  		this.setState({ passError: true })
  	}

  	if (this.state.login !== "" && this.state.pass !== "") {
  		this.processLogin()
  	}
  }

  processLogin() {
  	// if (this.state.login === "admin" && this.state.pass === "admin") {
  	// 	this.props.loginSuccess()
   //    window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Pomyślnie zalogowano."}}), true);
  	// } else {
  	// 	this.setState({ error: true, login: "", pass: "" })
  	// 	document.getElementById('login').focus()
   //    window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Coś poszło nie tak."}}), true);
  	// }
    let data  = { emailAddress: this.state.login, password: this.state.pass, otp: this.state.otp }
    fetch(this.props.API+"/user/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      body: JSON.stringify(data)
    })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      if (!result.status.success) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.data === "E_USER_WRONG_PASSWORD" || result.data === "E_USER_NOT_FOUND" ? "Niepoprawne dane" : "Coś poszło nie tak." }}), true);
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : result.data === "I_OTP_SENT" ? "Wysłano kod SMS." : "Pomyślnie zalogowano."}}), true);
      }
        if (result.status.success === true) {
          if (result.data === "I_OTP_SENT") {
            this.setState({ popup: true })
            setTimeout(() => { document.getElementById('code-input').focus() }, 600)
          } else {
            sessionStorage.setItem('token', result.data.token.uuid)
            this.props.loginSuccess(result.data)
            this.setState({ popup: false })
          }
        } else {
          this.setState({ error: true, login: "", pass: "" })
          document.getElementById('login').focus()
        }
    }, (error) => {
      console.log(error)
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Coś poszło nie tak."}}), true);
    })
  }

  render() {
    const Popup = (
      <div className="confirm sms-verification">
        <h4>Wpisz kod SMS</h4>
        <input id="code-input" type="text" placeholder="Kod SMS..." value={ this.state.otp } onChange={ (e) => { this.setState({ otp: e.target.value }) } }/>
        <div className="btn-holder">
          <a className="btn" onClick={ this.processLogin }><span>Zaloguj</span></a>
        </div>
      </div>
    )

    return (
      <section className="login">
        <div className={ this.state.anim ? "login-box show" : "login-box" }>
          <img className="login-logo" src="/assets/img/bat-logo.svg" alt="BAT Logo"/>
        	<h3>Logowanie do systemu</h3>
        	<div className="inputs">
        		{ this.state.error ? <p className="error">Niepoprawne dane logowania!</p> : null }
        		<input type="text" name="login" id="login" placeholder="Nazwa użytkownika" value={ this.state.login } onChange={ this.handleInputChange } required/>
        		{ this.state.loginError ? <p className="error">Podaj nazwę użytkownika</p> : null }
        		<input type="password" name="pass" id="pass" placeholder="Hasło" value={ this.state.pass } onChange={ this.handleInputChange } required/>
        		{ this.state.passError ? <p className="error">Podaj hasło</p> : null }
        		<div className="btn-holder">
        			<button className="btn btn-login" id="button" onClick={ this.checkLogin }><span>Zaloguj się&nbsp;&nbsp;></span></button>
        		</div>
        	</div>
        </div>
        <Modal open={ this.state.popup } hidePopup={ () => {  } }>{ Popup }</Modal>
      </section>
    )
  }
}
