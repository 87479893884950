import React from 'react'
import BaseComponent from './BaseComponent'
import Modal from './Modal'

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { confirm: false, preload: false }
    this._bind('clearCache', 'showConfirm', 'closeConfirm')
  }

  clearCache() {
    this.setState({ preloader: true })
    fetch(this.props.API+"/api/admin/cache/clear", {
      method: "POST",
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + sessionStorage.getItem('token')
      }
    })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      //console.log(result)
      this.setState({ confirm: false, preloader: false })
      if (result.data.status === false) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.error}}), true);
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Pomyślnie wyczyszczono."}}), true);
      }
    }, (error) => {
      console.log(error)
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Coś poszło nie tak."}}), true);
      this.setState({ preloader: false })
    })
  }

  showConfirm() {
    this.setState({ confirm: true })
  }

  closeConfirm() {
    this.setState({ confirm: false })
  }

  render () {

    const Confirm = (
      <div className="confirm">
        <h4>Czy jesteś pewny?</h4>
        <div className="btn-holder">
          <a className="btn" onClick={ this.closeConfirm }><span>Nie</span></a>
          <a className="btn" onClick={ this.clearCache }><span>Tak</span></a>
        </div>
      </div>
    )

    let fields = this.props.data.fields.map((item, i) => {
      if (item.type === "cache") {
        return (
          <div key={ i }>
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
            <a className="btn" onClick={ this.showConfirm }><span>Wyczyść cache</span></a>
          </div>
        )
      }
    })

    return (
      <section className="new">
        <div className="container">
          <h1>Ustawienia strony</h1>
          <form className="fields" data-parsley-validate>
            { fields }
            { this.state.preloader ? <div className="preloader"><span></span></div> : null }
          </form>
        </div>
        <Modal open={ this.state.confirm } hidePopup={ this.closeConfirm }>{ Confirm }</Modal>
      </section>
    )
  }
}
