import React from 'react'
import BaseComponent from './BaseComponent'
import { NavLink } from 'react-router-dom'
import 'parsleyjs'
import { SketchPicker } from 'react-color'
import Dropzone from './Dropzone'
import _ from 'lodash'
import Gallery from './Gallery'
import Tags from './Tags'

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = this.props.fields
    this._bind('handleInputChange', 'handleEditorChange', 'onImageChange', 'onTagsChange')
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      this.props.additionalFieldsChange(nextState)
    }
  }

  handleInputChange(event, editor) {
    const target = event.target
    let value = target.type === 'checkbox' ? target.checked : target.value
    let name = target.name

    this.setState({
      [name]: value
    })
  }

  handleEditorChange(name, content) {
    let value = content

    this.setState({
      [name]: value
    })
  }

  onImageChange(name, image) {
    let value = image

    this.setState({
      [name]: value
    })
  }

  onTagsChange(tags) {
    this.setState({ tags: tags })
  }

  render () {
    let selectedFields = []

    if (this.props.template) {
      selectedFields = _.filter(this.props.templates, { id: this.props.template })[0].additional_fields
    }

    let fields = selectedFields.map((item, i) => {
      if (item.type === "text") {
        return (
          <div key={ i }>
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
            <input type="text" name={ item.name } value={ this.props.fields[item.name] } onChange={ this.handleInputChange } required={ item.required ? true : false }/>
          </div>
        )
      } else if (item.type === "number") {
        return (
          <div key={ i }>
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
            <input type="number" name={ item.name } value={ this.props.fields[item.name] } onChange={ this.handleInputChange } required={ item.required ? true : false }/>
          </div>
        )
      } else if(item.type === "textarea") {
        return (
          <div key={ i }>
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
            <textarea name={ item.name } value={ this.props.fields[item.name] } onChange={ this.handleInputChange } required={ item.required ? true : false }></textarea>
          </div>
        )
      } else if(item.type === "color") {
        return (
          <div key={ i }>
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
            <SketchPicker id={ item.name } color={ this.props.fields[item.name] } onChangeComplete={ (e) => { this.setState({ [item.name]: e.hex }) } }/>
          </div>
        )
      } else if(item.type === "image") {
        return (
          <div key={ i }>
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
            <Dropzone image={ this.props.fields[item.name] } name={ item.name } onImageChange={ this.onImageChange } sessionId={ this.props.sessionId }/>
          </div>
        )
      } else if(item.type === "gallery") {
        let img = []

        if (this.props.fields[item.name] !== "") {
          img = this.props.fields[item.name]
        }
        return (
          <div key={ i }>
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
            <Gallery image={ img } name={ item.name } onImageChange={ this.onImageChange } sessionId={ this.props.sessionId }/>
          </div>
        )
      } else if (item.type === "tags") {
        return (
          <div key={ i }>
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
            <Tags sessionId={ this.props.sessionId } tags={ this.state.tags !== "" ? this.state.tags : [] } onTagsChange={ this.onTagsChange }/>
            <div className="tag-management"><NavLink to="/tagi">Zarządzaj tagami</NavLink></div>
          </div>
        )
      }
    })

    return (
      <div>
        <h2>Treści zależne od szablonu:</h2>
        { fields }
      </div>
    )
  }
}
