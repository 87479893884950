import React from 'react'
import BaseComponent from './BaseComponent'
import { NavLink } from 'react-router-dom'
import Dropzone from 'react-dropzone'

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { files: [], imageError: false, preloader: false }
    this._bind('onDrop', 'removeFiles', 'save')
  }

  onDrop(acceptedFiles) {
    this.setState({
      imageError: false
    })
    this.props.onFileChange(this.props.name, acceptedFiles)
  }

  removeFiles() {
    this.setState({
      files: []
    })
  }

  save() {
    let data  = {"type" : 'addImages', "params" : {}, "session_id" : this.props.sessionId}
    let formData = new FormData()

    if(typeof(this.state.files) !== "undefined" && this.state.files.length !== 0) {
      if(this.state.files !== undefined){
        if (this.state.files.length > 1) {
          for(var i = 0; i < this.state.files.length; i++) { 
            formData.append('file-'+i, this.state.files[i]) 
          }
        } else if (this.state.files.length == 1) {
          formData.append('file-0', this.state.files[0])
        }
      }
      formData.append('data', JSON.stringify(data))
    } else {
      formData = JSON.stringify(data)
    }

    fetch("/api/app.php", {
      method: "POST",
      body: formData
    })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      if (result.error) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.error}}), true);
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Pomyślnie dodano plik."}}), true);
      }
      this.setState({ preloader: false })
      this.props.onImageChange(this.props.name, result.result)
    }, (error) => {
      console.log(error)
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Coś poszło nie tak"}}), true);
      this.setState({ preloader: false })
    })
  }

  render () {
    let image = this.props[this.props.name] ? this.props[this.props.name][0] : ""

    return (
      <div className="dropzone-holder">
        <Dropzone maxSize={10000000} className={ this.state.imageError ? "dropzone dropzone-reject" : "dropzone" } activeClassName="dropzone-active" rejectClassName="dropzone-reject" multiple={ false } onDrop={ this.onDrop }>
            {({ isDragActive, isDragReject, acceptedFiles, rejectedFiles }) => {
              if (isDragReject) {
                return <span className="text">To nie jest właściwy format pliku.</span>
              }
              if (isDragActive) {
                return <span className="text">Upuść aby dodać</span>
              }
              return this.state.files.length || image
                ? <span className="text">{ image.name }</span>
                : <span className="text">Dodaj plik<br/><span className="small">Maksymalna waga pliku – 10MB.</span></span>
            }}
          </Dropzone>
      </div>
    )
  }
}
