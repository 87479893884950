import React from 'react'
import BaseComponent from './BaseComponent'
import { NavLink } from 'react-router-dom'
import Modal from './Modal'
import _ from 'lodash'
import ReactPaginate from 'react-paginate';

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { list: [], itemId: null, preloader: true, searchTerm: "", searchBy: "name", page: 0, pages: 0 }
    this._bind('delete', 'getData', 'searchUpdated', 'closeConfirm', 'handlePageClick')

    this.handleSearch = _.debounce( () => {
      this.setState({ list: [], preloader: true })
      this.getData()
    }, 600);
  }

  getData() {
    let url = this.props.API+this.props.data.endpointList
    let params = {
      "domain": {
        "uuid": this.props.selectedDomain.uuid
      },
      "page": this.state.page,
      "pageSize": 30,
      "filtered": this.props.data.listType ? [
          {
              "id": this.state.searchBy,
              "value": this.state.searchTerm
          },
          {
            "id": this.props.data.listType ? "type" : "",
            "value": this.props.data.listType ? this.props.data.listType : ""
          }
      ] : [
          {
              "id": this.state.searchBy,
              "value": this.state.searchTerm
          }
      ],
      "sorted": [
          {
              "id": "name",
              "desc": false
          }
      ]
    }
    fetch(url, {
      method: "POST",
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + sessionStorage.getItem('token')
        },
        body: JSON.stringify(params)
      })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      //console.log(result)
      let res = result.data ? result.data.rows : []
      if (res === null) {
        res = []
      }
      this.setState({ list: res, preloader: false, pages: result.data ? result.data.pages : [] })
    }, (error) => {
      console.log(error)
    })
  }

  componentDidMount() {
    this.getData()
  }

  delete(id) {
    this.setState({ preloader: true })
    let params = {
      domain: {
        uuid: this.props.selectedDomain.uuid
      }
    }
    let url = this.props.API+this.props.data.endpointDelete+id
    fetch(url, {
      method: "POST",
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + sessionStorage.getItem('token')
      },
      body: JSON.stringify(params)
    })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      //console.log(result)
      this.setState({ confirm: false, itemId: null })
      this.getData()
      if (!result.status.success) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.error}}), true);
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Pomyślnie usunięto."}}), true);
      }
    }, (error) => {
      console.log(error)
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Coś poszło nie tak."}}), true);
      this.setState({ preloader: false })
    })
  }

  searchUpdated(term) {
    this.setState({searchTerm: term})
  }

  closeConfirm() {
    this.setState({ confirm: false, itemId: null })
  }

  handlePageClick(data) {
    let selected = data.selected
    console.log(data)
    let offset = Math.ceil(selected * 30)

    this.setState({ page: data.selected, preloader: true }, () => {
      this.getData()
    })
  }

  render () {
    let list = null

    let filteredList = this.state.list

    const Confirm = (
      <div className="confirm">
        <h4>Czy jesteś pewny?</h4>
        <div className="btn-holder">
          <a className="btn" onClick={ this.closeConfirm }><span>Nie</span></a>
          <a className="btn" onClick={ this.delete.bind(null, this.state.itemId) }><span>Tak</span></a>
        </div>
      </div>
    )

    if (filteredList.length > 0) {
      list = filteredList.map((item, i) => {
        return (
          <li key={ i }>
            { item.image ? <div className="thumb">
              <img src={ item.image } alt=""/>
            </div> : null }
            <NavLink to={ this.props.data.route + "/" + item.uuid } style={{ textDecoration: "none" }}><h4 className={ item.image ? "withimg" : "" }>Nazwa: { item.alias ? item.alias : item.title ? item.title : item.name ? item.name : item.login }</h4></NavLink>
            <div className="controls">
              { this.props.user.role === "admin" || this.props.user.role === "Moderator" ? <div onClick={ () => { this.setState({ confirm: true, itemId: item.uuid }) } }><img src="/assets/img/icons/delete.png" alt=""/><span>{ this.props.data.delete }</span></div> : null }
              { this.props.user.role === "admin" || this.props.user.role === "Moderator" ? <div><NavLink to={ this.props.data.route + "/" + item.uuid }><img src="/assets/img/icons/edit.png" alt=""/><span>{ this.props.data.edit }</span></NavLink></div> : <div><NavLink to={ this.props.data.route + "/" + item.uuid }><img src="/assets/img/icons/edit.png" alt=""/><span>Podgląd</span></NavLink></div> }
            </div>
          </li>
        )
      })
    }

    return (
      <section className="list">
        <div className="container">
    			<h1>{ this.props.data.name }</h1>
          { this.props.user.role === "admin" || this.props.user.role === "Moderator" ? <NavLink to={ this.props.data.new } className="btn"><span>{ this.props.data.add }</span></NavLink> : null }
          <div className="search-box">
            <div className="search-input">
              <input type="text" placeholder="Wyszukaj" value={ this.state.searchTerm } onChange={ (e) => { this.setState({ searchTerm: e.target.value }); this.handleSearch() } }/>
            </div>
            <div className="search-input si-select">
              <label htmlFor="searchBy">Szukaj według:</label>
              <select id="searchBy" name="searchBy" value={ this.state.searchBy } onChange={ (e) => { this.setState({ searchBy: e.target.value }) } }>
                { _.filter(this.props.data.fields, { type: "text" }).map((item, i) => {
                  return (
                    <option key={ i } value={ item.name }>{ item.placeholder }</option>
                  )
                }) }
              </select>
            </div>
          </div>
          { this.state.pages > 0 ? <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={this.state.pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={ this.handlePageClick }
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          /> : null }
          <ul className="list-items">
            { filteredList.length > 0 ? list : <h5>Brak wyników!</h5> }
            { this.state.preloader ? <div className="preloader"><span></span></div> : null }
          </ul>
          { this.state.pages > 0 ? <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={this.state.pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={ this.handlePageClick }
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          /> : null }
	  	  </div>
        <Modal open={ this.state.confirm } hidePopup={ this.closeConfirm }>{ Confirm }</Modal>
      </section>
    )
  }
}
