import React from 'react'
import BaseComponent from './BaseComponent'
import { NavLink } from 'react-router-dom'
import Dropzone from 'react-dropzone'
import _ from 'lodash'

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { files: [], imageError: false, preloader: false }
    this._bind('onDrop', 'removeFiles', 'remove', 'save')
  }

  onDrop(acceptedFiles) {
    this.setState({
      imageError: false,
      files: acceptedFiles
    })
    this.save(acceptedFiles)
  }

  removeFiles() {
    this.setState({
      files: []
    })
    if(this.props.image && this.props.image.uuid) this.remove(this.props.image.uuid)
  }

  remove(uuid) {
    this.setState({ preloader: true })
    let url = this.props.API+"/edit/trash/recursive/file/"+uuid
    fetch(url, {
      method: "POST",
      headers: {
          "Authorization": "Bearer " + sessionStorage.getItem('token')
      }
    })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      console.log(result)
      if (!result.status.success) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.error}}), true);
        this.setState({ preloader: false })
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Usunięto plik. Pamiętaj aby zapisać zmiany!"}}), true);
        this.setState({ preloader: false })
        this.props.onChange(null)
      }
    }, (error) => {
      //console.log(error)
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Coś poszło nie tak."}}), true);
      this.setState({ preloader: false })
    })
  }

  save(files) {
    this.setState({ preloader: true })
    let formData = new FormData()
    for (var f = 0; f < files.length; f++) {
        formData.append('files[0]', files[f], files[f].name);
    }

    let params = {
      domain: {
        uuid: this.props.selectedDomain.uuid
      }
    }

    formData.append('_input', JSON.stringify(params));
    let data = formData;

    let url = this.props.API+"/edit/file/create"
    fetch(url, {
      method: "POST",
      headers: {
          "Authorization": "Bearer " + sessionStorage.getItem('token')
      },
      body: data
    })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      if (!result.status.success) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.error}}), true);
        this.setState({ preloader: false })
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Dodano plik. Pamiętaj aby zapisać zmiany!"}}), true);
        this.setState({ preloader: false })
        this.props.onChange(result.data[0])
      }
    }, (error) => {
      //console.log(error)
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Coś poszło nie tak."}}), true);
      this.setState({ preloader: false })
    })
  }

  render () {
    let image = this.state.files.length > 0 ? this.state.files[0] : this.props.image ? this.props.image : ""

    return (
      <div className="dropzone-holder">
        <Dropzone maxSize={10000000} className={ this.state.imageError ? "dropzone dropzone-reject" : "dropzone" } activeClassName="dropzone-active" rejectClassName="dropzone-reject" multiple={ false } onDrop={ this.onDrop }>
            {({ isDragActive, isDragReject, acceptedFiles, rejectedFiles }) => {
              if (isDragReject) {
                return <span className="text">To nie jest właściwy format pliku.</span>
              }
              if (isDragActive) {
                return <span className="text">Upuść aby dodać</span>
              }
              return this.state.files.length || image
                ? <div><img src={ image.preview || image.url } style={{ maxHeight: "300px", display: "block", margin: "0 auto" }}/><span className="text" style={{ backgroundColor: "#fff", padding: "5px" }}>{ image.name }</span></div>
                : <span className="text">Dodaj plik<br/><span className="small">Maksymalna waga pliku – 10MB.</span></span>
            }}
          </Dropzone>
          { this.props.image && this.props.image.uuid ? <div className="remove-file" onClick={ () => { this.removeFiles() } }>Usuń</div> : null }
          { this.state.preloader ? <div className="preloader"><span></span></div> : null }
      </div>
    )
  }
}
