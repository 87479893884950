import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

export default class extends Component {

  render () {

    return (
      <section className="error">
        <div className="container">
    			<h1>404</h1>
  				<h3 className="error">Podana strona nie istnieje!</h3>
  				<NavLink to="/">&lt;&nbsp;&nbsp;Wróć na stronę główną.</NavLink>
	  	  </div>
      </section>
    )
  }
}
