import React from 'react'
import BaseComponent from './BaseComponent'
import _ from 'lodash'
import Modal from './Modal'

export default class extends BaseComponent {

  constructor(props) {
  	super(props)
  	this.state = { 
  		confirm: false, 
  		itemId: null, 
  		edit: false,
  		itemObject: {
	  		prodNo: "",
  			no: "",
  			count: "",
  			initialMin: "",
  			initialMax: "",
  			mileage: "",
  			percentage: "",
  			provision: "",
  			purchase: ""
  	  }
  	}
    
  	this._bind('delete', 'resetObject', 'closeEdit', 'setObject', 'openEdit', 'editObject', 'saveObject', 'closeConfirm')
  }

  delete(id) {
    this.setState({ preloader: true, confirm: false })
    let params = { "id": id }
    fetch(this.props.API+"/api/admin/data/delete/barem", {
      method: "POST",
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + sessionStorage.getItem('token')
      },
      body: JSON.stringify(params)
    })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      //console.log(result)
      this.setState({ confirm: false, itemId: null })
      if (result.error) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.error}}), true);
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Pomyślnie usunięto."}}), true);
        this.props.getData()
      }
    }, (error) => {
      console.log(error)
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Coś poszło nie tak."}}), true);
      this.setState({ preloader: false })
    })
  }

  openEdit(id) {
    this.setState({ edit: true, itemId: id })
    if (id) {
      this.setObject(id)
    }
  }

  closeEdit() {
  	this.setState({ edit: false, itemId: null })
  	this.resetObject()
  }

  setObject(id) {
    let barem = _.filter(this.props.barems, { id: id })[0]

    let item = {
      prodNo: barem.prodNo,
      no: barem.no,
      count: barem.count,
      initialMin: barem.initialMin ? barem.initialMin : 0,
      initialMax: barem.initialMax ? barem.initialMax : 0,
      mileage: barem.mileage,
      percentage: barem.percentage,
      provision: barem.provision,
      purchase: barem.purchase
    }

    this.setState({ itemObject: item })
  }

  resetObject() {
  	let item = {
  		prodNo: "",
  		no: "",
  		count: "",
  		initialMin: "",
  		initialMax: "",
  		mileage: "",
  		percentage: "",
  		provision: "",
  		purchase: ""
  	}

  	this.setState({ itemObject: item })
  }

  editObject(event) {
    const target = event.target
    let value = target.type === 'checkbox' ? target.checked : target.value
    let name = target.name

    let item = this.state.itemObject

    item[name] = value

    this.setState({ itemObject: item })
  }

  saveObject() {
    let barems = this.props.barems
    if (this.state.itemId) {
      let singleBarem = _.filter(barems, { id: this.state.itemId })[0]
      let baremIndex = barems.indexOf(singleBarem)

      barems[baremIndex].prodNo = this.state.itemObject.prodNo 
      barems[baremIndex].no = this.state.itemObject.no 
      barems[baremIndex].count = this.state.itemObject.count 
      barems[baremIndex].initialMin = this.state.itemObject.initialMin 
      barems[baremIndex].initialMax = this.state.itemObject.initialMax 
      barems[baremIndex].mileage = this.state.itemObject.mileage 
      barems[baremIndex].percentage = this.state.itemObject.percentage 
      barems[baremIndex].provision = this.state.itemObject.provision 
      barems[baremIndex].purchase = this.state.itemObject.purchase 
    } else {
      barems.push(this.state.itemObject)
    }

    this.props.onBaremChange(barems)
    this.closeEdit()
  }

  closeConfirm() {
    this.setState({ confirm: false, itemId: null })
  }

  render () {

  	let edit = (
  		<div className="popup-inner">
  			<form className="fields" data-parsley-validate>
	            <div className="add-top" onClick={ this.saveObject }>Zapisz zmiany</div>
	            <div className="back-top" onClick={ () => { this.closeEdit() } }>Powrót</div>
	            <div>
		            <h4>Nr produktu<span>*</span></h4>
		            <input type="text" name="prodNo" value={ this.state.itemObject.prodNo } onChange={ this.editObject } required/>
  		        </div>
  		        <div>
  		            <h4>Nr baremy<span>*</span></h4>
  		            <input type="text" name="no" value={ this.state.itemObject.no } onChange={ this.editObject } required/>
  		        </div>
  		        <div>
  		            <h4>Liczba rat<span>*</span></h4>
  		            <input type="text" name="count" value={ this.state.itemObject.count } onChange={ this.editObject } required/>
  		        </div>
  		        <div>
  		            <h4>Wpłata min<span>*</span></h4>
  		            <input type="text" name="initialMin" value={ this.state.itemObject.initialMin } onChange={ this.editObject } required/>
  		        </div>
  		        <div>
  		            <h4>Wpłata max<span>*</span></h4>
  		            <input type="text" name="initialMax" value={ this.state.itemObject.initialMax } onChange={ this.editObject } required/>
  		        </div>
  		        <div>
  		            <h4>Przebieg<span>*</span></h4>
  		            <input type="text" name="mileage" value={ this.state.itemObject.mileage } onChange={ this.editObject } required/>
  		        </div>
  		        <div>
  		            <h4>Oprocentowanie<span>*</span></h4>
  		            <input type="text" name="percentage" value={ this.state.itemObject.percentage } onChange={ this.editObject } required/>
  		        </div>
  		        <div>
  		            <h4>Prowizja<span>*</span></h4>
  		            <input type="text" name="provision" value={ this.state.itemObject.provision } onChange={ this.editObject } required/>
  		        </div>
  		        <div>
  		            <h4>Wykup<span>*</span></h4>
  		            <input type="text" name="purchase" value={ this.state.itemObject.purchase } onChange={ this.editObject } required/>
  		        </div>
	            <div className="add-bottom" onClick={ this.saveObject }>Zapisz zmiany</div>
	            <div className="back-bottom" onClick={ () => { this.closeEdit() } }>Powrót</div>
	        </form>
  		</div>
  	)

  	const values = this.props.barems.map((item, i) => {
  		return (
  			<tr key={ i }>
  				<td>{ item.prodNo }</td>
  				<td>{ item.no }</td>
  				<td>{ item.count }</td>
  				<td>{ item.initialMin }</td>
  				<td>{ item.initialMax }</td>
  				<td>{ item.mileage }</td>
  				<td>{ item.percentage }</td>
  				<td>{ item.provision }</td>
  				<td>{ item.purchase }</td>
  				{ this.props.user.role === "admin" || this.props.user.role === "Moderator" ? <td><span className="edit" onClick={ () => { this.openEdit(item.id) } }>Edytuj</span><br/><span className="delete" onClick={ () => { this.setState({ confirm: true, itemId: item.id }) } }>Kasuj</span></td> : null }
  			</tr>
  		)
  	})

    const Confirm = (
      <div className="confirm">
        <h4>Czy jesteś pewny?</h4>
        <div className="btn-holder">
          <a className="btn" onClick={ this.closeConfirm }><span>Nie</span></a>
          <a className="btn" onClick={ this.delete.bind(null, this.state.itemId) }><span>Tak</span></a>
        </div>
      </div>
    )

    return (
      <div className="barem-list">
      	<table style={{ width: "100%" }}>
      		<tbody>
    			  <tr>
    			    <th>Nr produktu</th>
    			    <th>Nr baremy</th> 
    			    <th>Liczba rat</th>
    			    <th>Wpłata min</th>
    			    <th>Wpłata max</th>
    			    <th>Przebieg</th>
    			    <th>Oprocentowanie</th>
    			    <th>Prowizja</th>
    			    <th>Wykup</th>
    			    { this.props.user.role === "admin" || this.props.user.role === "Moderator" ? <th>Opcje</th> : null }
    			  </tr>
    			  { values }
    		  </tbody>
    		</table>
    		{ this.props.user.role === "admin" || this.props.user.role === "Moderator" ? <div className="btn-holder"><button className="btn" onClick={ (e) => { e.preventDefault(); this.openEdit() } }><span>Dodaj baremę</span></button></div> : null }
        <Modal open={ this.state.edit } hidePopup={ this.closeEdit }>{ edit }</Modal>
        <Modal open={ this.state.confirm } hidePopup={ this.closeConfirm }>{ Confirm }</Modal>
      </div>
    )
  }
}
