import React from 'react'
import BaseComponent from './BaseComponent'
import { NavLink } from 'react-router-dom'
import _ from 'lodash'

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { filters: [] }
    this._bind('onChangeFilter', 'getData')
  }

  componentWillMount() {
    this.getData()
  }

  getData() {
    let url = this.props.API+"/edit/map/filter/list"
    let params = {
      "domain": {
        "uuid": this.props.selectedDomain.uuid
      },
      "page": 0,
      "pageSize": 9999,
      "filtered": [
          {
              "id": "name",
              "value": ""
          }
      ],
      "sorted": [
          {
              "id": "name",
              "desc": false
          }
      ]
    }
    fetch(url, {
      method: "POST",
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + sessionStorage.getItem('token')
        },
        body: JSON.stringify(params)
      })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      //console.log(result)
      let res = result.data.rows
      if (res === null) {
        res = []
      }
      this.setState({ filters: res, preloader: false })
    }, (error) => {
      console.log(error)
    })
  }

  onChangeFilter(filters) {
    this.props.onChangeFilters(filters)
  }

  render () {
    let filters = this.state.filters.map((item, i) => {
      let isActive = _.filter(this.props.activeFilters, { uuid: item.uuid })[0] ? true : false

      return (
          <div key={ i } className="checkbox">
            <h4>{ item.name }</h4>
            <input type="checkbox" id={ i } name={ item.name } value={ isActive } onChange={ (e) => {
              if (isActive) {
                let index = _.findIndex(this.props.activeFilters, { uuid: item.uuid })
                let activeFilters = this.props.activeFilters ? this.props.activeFilters : []
                activeFilters.splice(index, 1)
                this.onChangeFilter(activeFilters)
              } else {
                let activeFilters = this.props.activeFilters ? this.props.activeFilters : []
                activeFilters.push(item)
                this.onChangeFilter(activeFilters)
              }
            } } checked={ isActive ? true : false }/>
            <label htmlFor={ i }></label>
          </div>
        )
    })

    return (
      <div>
        <h2>Filtry:</h2>
        { filters }
      </div>
    )
  }
}
