import React from 'react'
import BaseComponent from './BaseComponent'
import Dropzone from 'react-dropzone'

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { files: [], imageError: false, preloader: false, confirm: false, idemId: 0 }
    this._bind('onDrop', 'removeFiles', 'add', 'save', 'delete')
  }

  onDrop(acceptedFiles) {
    this.setState({
      files: acceptedFiles,
      imageError: false
    })
  }

  removeFiles() {
    this.setState({
      files: []
    })
  }

  add(e) {
    e.preventDefault()

    if(this.state.files.length > 0) {
      this.setState({ preloader: true, imageError: false })
      this.save()
    } else {
      this.setState({ imageError: true })
    }
  }

  delete(id, e) {
    e.preventDefault()

    let temp = this.props.image
    let index = temp.indexOf(id)
    if (index > -1) {
      temp.splice(index, 1)
    }
    this.setState({ confirm: false })
  }

  save() {
    let data  = {"type" : 'addImages', "params" : {}, "session_id" : this.props.sessionId}
    let formData = new FormData()

    if(typeof(this.state.files) !== "undefined" && this.state.files.length !== 0) {
      if(this.state.files !== undefined){
        if (this.state.files.length > 1) {
          for(var i = 0; i < this.state.files.length; i++) { 
            formData.append('file-'+i, this.state.files[i]) 
          }
        } else if (this.state.files.length === 1) {
          formData.append('file-0', this.state.files[0])
        }
      }
      formData.append('data', JSON.stringify(data))
    } else {
      formData = JSON.stringify(data)
    }

    fetch("/api/app.php", {
      method: "POST",
      body: formData
    })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      if (result.error) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.error}}), true);
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Pomyślnie dodano zdjęcia."}}), true);
      }
      this.setState({ preloader: false, files: [] })
      this.props.onImageChange(this.props.name, this.props.image.concat(result.result))
    }, (error) => {
      console.log(error)
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Coś poszło nie tak."}}), true);
      this.setState({ preloader: false })
    })
  }

  render () {
    let images = this.props.image || []

    let thumbs = images.map((item, i) => {
      return <li key={ i }><img src={ item } alt=""/><p className="del" onClick={ () => { this.setState({ confirm: true, itemId: item }) } }>Usuń</p></li>
    })

    return (
      <div className="dropzone-holder">
        <ul className="images-list">
          { thumbs }
        </ul>
        <Dropzone maxSize={10000000} className={ this.state.imageError ? "dropzone dropzone-reject" : "dropzone" } activeClassName="dropzone-active" rejectClassName="dropzone-reject" multiple={ true } onDrop={ this.onDrop } accept={ "image/*" }>
            {({ isDragActive, isDragReject, acceptedFiles, rejectedFiles }) => {
              if (isDragReject) {
                return <span className="text">To nie jest właściwy format pliku.</span>
              }
              if (isDragActive) {
                return <span className="text">Upuść aby dodać</span>
              }
              return this.state.files.length
                ? <div className="contest-image image-preview">{ this.state.files.map((item, i) => { return <img key={ i } src={ item.preview } alt=""/> }) }</div>
                : <span className="text">Dodaj pliki<br/><span className="small">Rodzaje plików, które możesz dodać – jpg/gif/png.<br/>Maksymalna waga plików – 10MB.<br/>Maksymalna ilość plików - 20.</span></span>
            }}
          </Dropzone>
          <button className="btn" onClick={ this.add }><span>Upload zdjęć</span></button>
          { this.state.preloader ? <div className="preloader"><span></span></div> : null }
          { this.state.confirm ? <div className="confirm-modal">
            <div className="confirm-inner">
              <p>Czy jesteś pewny?</p>
              <button className="btn" onClick={ this.delete.bind(null, this.state.itemId) }><span>Tak</span></button>
              <button className="btn" onClick={ () => { this.setState({ confirm: false, itemId: null }) } }><span>Nie</span></button>
            </div>
          </div> : null }
      </div>
    )
  }
}
