import React from 'react'
import BaseComponent from './BaseComponent'
import _ from 'lodash'

export default class extends BaseComponent {

  constructor(props) {
  	super(props)
  	this.state = { 
  		confirm: false, 
  		itemId: null, 
  		edit: false,
  		itemObject: {
	  		prodNo: "",
  			no: "",
  			count: "",
  			initialMin: "",
  			initialMax: "",
  			mileage: "",
  			percentage: "",
  			provision: "",
  			purchase: ""
  	  }
  	}
    
  	this._bind('setDefault')
  }

  setDefault(id) {
    this.props.setDefault(id)
  }

  render () {
  	const values = this.props.list.map((item, i) => {
  		return (
  			<tr key={ i }>
  				<td>{ item.name }</td>
  				<td><a href={ item.url } target="_blank">{ item.url }</a></td>
          <td>{ item.default ? "TAK" : "NIE" }</td>
  				{ this.props.user.role === "admin" || this.props.user.role === "Moderator" ? <td><span className="edit" onClick={ () => { this.setDefault(item.uuid) } }>Ustaw jako domyślny</span></td> : null }
  			</tr>
  		)
  	})

    return (
      <div className="barem-list">
      	<table style={{ width: "100%" }}>
      		<tbody>
    			  <tr>
    			    <th>Nazwa</th>
              <th>Ścieżka</th>
    			    <th>Domyślny</th>
    			    { this.props.user.role === "admin" || this.props.user.role === "Moderator" ? <th>Opcje</th> : null }
    			  </tr>
    			  { values }
    		  </tbody>
    		</table>
      </div>
    )
  }
}
