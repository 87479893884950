import React, { useState, useEffect } from 'react'
import BaseComponent from './BaseComponent'
import { NavLink } from 'react-router-dom'
import $ from 'jquery'
import 'parsleyjs'
import { SketchPicker } from 'react-color'
import Dropzone from './Dropzone'
import Files from './Files'
import Gallery from './Gallery'
import Tags from './Tags'
import Roles from './Roles'
import BaremList from './BaremList'
import AdditionalFields from './AdditionalFields'
import Modules from './Modules'
import SingleFileUpload from './SingleFileUpload'

function Categories({ API, selectedDomain, selectCategory, selectedCat }) {
  const [categories, setCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState("")

  const getCategories = () => {
    let url = API+"/edit/category/list"
    let params = {
      domain: {
        uuid: selectedDomain.uuid
      },
      page: 0,
      pageSize: 100
    }

    fetch(url, {
      method: "POST",
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + sessionStorage.getItem('token')
        },
        body: JSON.stringify(params)
      })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      if (result.status.success) {
        setCategories(result.data.rows)
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.data.flag === "E_NO_AUTH" ? "Nie masz uprawnień." : "Coś poszło nie tak." }}), true);
      }
    }, (error) => {
      console.log(error)
    })
  }

  useEffect(() => {
    getCategories()
  }, [])

  useEffect(() => {
    setSelectedCategory(selectedCat.uuid)
  }, [selectedCat.uuid])
  
  return (
    <select value={ selectedCategory } onChange={ (e) => { setSelectedCategory(e.target.value); selectCategory(e.target.value) } }>
      <option value="">Wybierz kategorię</option>
      { categories.map((item, i) => {
        return (
          <option key={ i } value={ item.uuid }>{ item.name }</option>
        )
      }) }
    </select>
  )
}

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { options: [], preloader: true, passerr: false, repeat: "", files: [] }
    this._bind('handleInputChange', 'handleEditorChange', 'onImageChange', 'fillOptions', 'add', 'save', 'onTagsChange', 'additionalFieldsChange', 'onBaremChange', 'onRoleChange', 'handlePassChange', 'countMin', 'countMax', 'onFileChange')
  }

  componentWillMount() {
    this.props.data.fields.map((item, i) => {
      this.setState({
        [item.name]: item.value ? item.value : ""
      })

      if (item.name === "changePassword") {
        this.setState({
          [item.name]: "1"
        })
      }

      if (item.type === "checkbox") {
        this.setState({
          [item.name]: "0"
        })
      }

      if (item.name === "consent") {
        this.setState({ consent: [] })
      }

      if (item.name === "module") {
        this.setState({ module: [] })
      }

      if (item.optionMethod) {
        this.fillOptions(item.optionMethod)
      } else {
        this.setState({ preloader: false })
      }

      return null
    })
  }

  componentDidMount() {
    if (this.props.user.role !== "admin" && this.props.user.role !== "Moderator") {
      let elements = document.querySelectorAll("input, textarea, select")
      elements.forEach((element) => {
        element.disabled = true
      })
    }
  }

  fillOptions(method) {
    if (method === "template") {
      this.setState({ options: this.props.templates, preloader: false })
    } else {
      let data  = {"type" : method, "params" : {}, "session_id" : this.props.sessionId}
      fetch("/api/app.php", {
        method: "POST",
        body: JSON.stringify(data)
      })
      .then((response) => {
          return response.json()
      })
      .then((result) => {
        //console.log(result)
        let res = result.result
        if (res === null) {
          res = []
        }
        this.setState({ options: res, preloader: false })
      }, (error) => {
        console.log(error)
        this.setState({ preloader: false })
      })
    }
  }

  handleInputChange(event, editor) {
    const target = event.target
    let value = ""
    if (target.type === 'checkbox') {
      if (target.checked) {
        value = "1"
      } else {
        value = "0"
      }
    } else {
      value = target.value
    }
    let name = target.name

    if(this.props.data.content === "category") {
      let tempCat = this.state.category || {}
      tempCat[name] = value
      this.setState({category: tempCat})
    }

    this.setState({
      [name]: value
    })
  }

  handleEditorChange(name, event, editor) {
    let value = editor.getContent()

    this.setState({
      [name]: value
    })
  }

  onImageChange(name, image) {
    let value = image

    this.setState({
      [name]: value
    })
  }

   onFileChange(name, file) {
    this.setState({
      [name]: file
    })
  }

  handlePassChange(event) {
    const target = event.target
    let value = target.value
    let name = target.name

    this.setState({
      [name]: value
    })
  }

  onTagsChange(tags) {
    this.setState({ productList: tags })
  }

  onBaremChange(barems) {
    this.setState({ baremList: barems })
  }

  additionalFieldsChange(data) {
    if (data !== this.state.additional_fields) {
      this.setState({ additional_fields: data })
    }
  }

  add(e) {
    e.preventDefault()

    if (this.props.data.table === "user") {
      if (this.state.password !== this.state.repeat) {
        this.setState({ passerr: true })
      } else {
        this.setState({ passerr: false })
      }

      if($('.fields').parsley().validate() && (this.state.password === this.state.repeat)) {
        this.save()
      }
    } else {
      if($('.fields').parsley().validate()) {
        this.save()
      }
    }
  }

  onRoleChange(e) {
    const target = e.target
    let value = []
    value.push({ id: parseInt(e.target.value, 10) })
    let name = target.name

    this.setState({
      [name]: value
    })
  }

  save() {
    this.setState({ preloader: true })
    if (this.props.data.table === "files" && typeof(this.state.files) !== "undefined" && this.state.files.length !== 0) {
      let formData = new FormData()
      for (var f = 0; f < this.state.files.length; f++) {
          formData.append('files[0]', this.state.files[f], this.state.files[f].name);
      }
  
      let params = {}
      if (this.state.filter && this.state.filter.length > 0) {
        params = {
          domain: {
            uuid: this.props.selectedDomain.uuid
          },
          [this.props.data.content]: this.state,
          filter: this.state.filter
        }
        delete params[this.props.data.content].filter
      } else if (this.state.consent && this.state.consent.length > 0) {
        params = {
          domain: {
            uuid: this.props.selectedDomain.uuid
          },
          [this.props.data.content]: this.state,
          consent: this.state.consent
        }
        delete params[this.props.data.content].consent
      } else if (this.state.module && this.state.module.length > 0) {
        params = {
          domain: {
            uuid: this.props.selectedDomain.uuid
          },
          [this.props.data.content]: this.state,
          module: this.state.module
        }
        delete params[this.props.data.content].module
      } else {
        params = {
        domain: {
            uuid: this.props.selectedDomain.uuid
          },
          [this.props.data.content]: this.state
        }
      }

      if(this.props.data.content !== "category") {
        if(this.state.category && this.state.category.uuid) {
          params.category = this.state.category
          delete params[this.props.data.content].category
        } else {
          params.category = null
          delete params[this.props.data.content].category
        }
      }

      formData.append('_input', JSON.stringify(params));
      let data = formData;

      let url = this.props.API+this.props.data.endpointAdd
      fetch(url, {
        method: "POST",
        headers: {
            "Authorization": "Bearer " + sessionStorage.getItem('token')
        },
        body: data
      })
      .then((response) => {
          return response.json()
      })
      .then((result) => {
        if (!result.status.success) {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.error}}), true);
        } else {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Pomyślnie dodano."}}), true);
          this.props.history.push(this.props.data.route + "/" + result.data)
        }
      }, (error) => {
        console.log(error)
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Coś poszło nie tak."}}), true);
        this.setState({ preloader: false })
      })
    } else {
      let params = {}
      if (this.state.filter && this.state.filter.length > 0) {
        params = {
          domain: {
            uuid: this.props.selectedDomain.uuid
          },
          [this.props.data.content]: this.state,
          filter: this.state.filter
        }
        delete params[this.props.data.content].filter
      } else if (this.state.consent && this.state.consent.length > 0) {
        params = {
          domain: {
            uuid: this.props.selectedDomain.uuid
          },
          [this.props.data.content]: this.state,
          consent: this.state.consent
        }
        delete params[this.props.data.content].consent
      } else if (this.state.module && this.state.module.length > 0) {
        params = {
          domain: {
            uuid: this.props.selectedDomain.uuid
          },
          [this.props.data.content]: this.state,
          module: this.state.module
        }
        delete params[this.props.data.content].module
      } else {
        params = {
        domain: {
            uuid: this.props.selectedDomain.uuid
          },
          [this.props.data.content]: this.state
        }
      }

      if(this.props.data.content !== "category") {
        if(this.state.category && this.state.category.uuid) {
          params.category = this.state.category
          delete params[this.props.data.content].category
        } else {
          params.category = null
          delete params[this.props.data.content].category
        }
      }
      
      let url = this.props.API+this.props.data.endpointAdd
      fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + sessionStorage.getItem('token')
        },
        body: JSON.stringify(params)
      })
      .then((response) => {
          return response.json()
      })
      .then((result) => {
        if (!result.status.success) {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.error}}), true);
        } else {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Pomyślnie dodano."}}), true);
          this.props.history.push(this.props.data.route + "/" + result.data)
        }
      }, (error) => {
        console.log(error)
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Coś poszło nie tak."}}), true);
        this.setState({ preloader: false })
      })
    }
  }

  countMin(name, from, e) {
    e.preventDefault()
    let items = []

    for (var i = 0; i < this.state.baremList.length; i++) {
      items.push(parseInt(this.state.baremList[i][from]))
    }

    let min = Math.min(...items)

    this.setState({ [name]: min })
  }

  countMax(name, from, e) {
    e.preventDefault()
    let items = []

    for (var i = 0; i < this.state.baremList.length; i++) {
      items.push(parseInt(this.state.baremList[i][from]))
    }

    let max = Math.max(...items)

    this.setState({ [name]: max })
  }

  render () {
    let fields = this.props.data.fields.map((item, i) => {

      if (item.dependOn) {
        if (!(this.state[item.dependOn.name] === item.dependOn.value)) {
          return
        }
      }

      if (item.name === "changePassword") {
        return
      }

      if (item.type === "text") {
        return (
          <div key={ i }>
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
            <input type="text" name={ item.name } value={ this.state[item.name] } onChange={ this.handleInputChange } required={ item.required ? true : false }/>
          </div>
        )
      } else if (item.type === "modules") {
        return (
          <div key={ i } className="title">
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
            <Modules API={ this.props.API } selectedDomain={ this.props.selectedDomain } items={ this.state[item.name] } files={ this.state.files } addedFiles={ this.state.file } changeModuleItems={ (items) => { this.setState({ module: items }) } } addModule={ () => { let modules = this.state.module; modules.push({ type: "p", html: "", trash: false }); this.setState({ module: modules }) } } onFilesChange={ (files) => { this.setState({ files: files }) } }/>
          </div>
        )
      } else if (item.type === "category") {
        return (
          <div key={ i } className="title">
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
            <Categories API={ this.props.API } items={ this.state[item.name] } selectedDomain={ this.props.selectedDomain } selectCategory={ (cat) => { this.setState({ category: { uuid: cat } }) } } selectedCat={ this.state.category || {} }/>
          </div>
        )
      } else if (item.type === "subtitle") {
        return (
          <div key={ i } className="title">
            <h2>{ item.value }</h2>
          </div>
        )
      } else if (item.type === "null") {
        return (
          <div key={ i } className="null"></div>
        )
      } else if (item.type === "smallSubtitle") {
        return (
          <div key={ i } className="title">
            <h3>{ item.value }</h3>
          </div>
        )
      } else if (item.type === "number") {
        return (
          <div key={ i }>
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
            <input type="number" name={ item.name } value={ this.state[item.name] } onChange={ this.handleInputChange } required={ item.required ? true : false }/>
          </div>
        )
      } else if (item.type === "checkbox") {
        return (
          <div key={ i } className="checkbox">
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
            <input type="checkbox" id={ i } name={ item.name } onChange={ this.handleInputChange } checked={ this.state[item.name] === "1" ? true : false }/>
            <label htmlFor={ i }></label>
          </div>
        )
      } else if(item.type === "option") {
        let options = []
        if (item.optionMethod === "template") {
          options = this.state.options.map((itemj, j) => {
            return (
              <option key={ j } value={ itemj.id } required={ item.required ? true : false }>{ itemj.name }</option>
            )
          })
        } else if (this.state.options.length > 0) {
          options = this.state.options.map((itemj, j) => {
            return (
              <option key={ j } value={ itemj.id } required={ item.required ? true : false }>{ itemj.title }</option>
            )
          })
        } else {
          options = item.options.map((itemj, j) => {
            return (
              <option key={ j } value={ itemj.value } required={ item.required ? true : false }>{ itemj.name }</option>
            )
          })
        }

        return (
          <div key={ i }>
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
            <select name={ item.name } onChange={ this.handleInputChange } value={ this.state[item.name] }>
              <option value="0">Wybierz</option>
              { options }
            </select>
          </div>
        )
      } else if(item.type === "textarea") {
        return (
          <div key={ i }>
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
            <textarea name={ item.name } value={ this.state[item.name] } onChange={ this.handleInputChange } required={ item.required ? true : false }></textarea>
          </div>
        )
      } else if(item.type === "color") {
        return (
          <div key={ i }>
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
            <SketchPicker id={ item.name } color={ this.state[item.name] } onChangeComplete={ (e) => { this.setState({ [item.name]: e.hex }) } }/>
          </div>
        )
      } else if(item.type === "image") {
        let val = this.state[item.name] || {}

        return (
          <div key={ i }>
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
            <SingleFileUpload selectedDomain={ this.props.selectedDomain } API={ this.props.API } image={ val.mainImage } onChange={ (img) => { val.mainImage = img; this.setState({ [item.name]: val }) } }/>
            <p className="input-desc">Tekst alternatywny</p>
            <input type="text" value={ val.mainImageAlt } onChange={ (e) => { val.mainImageAlt = e.target.value; this.setState({ [item.name]: val }) } }/>
          </div>
        )
      } else if(item.type === "file") {
        return (
          <div key={ i }>
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
            <Files files={ this.state[item.name] } name={ item.name } onFileChange={ this.onFileChange }/>
          </div>
        )
      } else if(item.type === "gallery") {
        let img = []

        if (this.state[item.name] !== "") {
          img = this.state[item.name]
        }
        return (
          <div key={ i }>
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
            <Gallery image={ img } name={ item.name } onImageChange={ this.onImageChange } sessionId={ this.props.sessionId }/>
          </div>
        )
      } else if (item.type === "tags") {
        return (
          <div key={ i }>
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
            <Tags sessionId={ this.props.sessionId } tags={ this.state.tags !== "" ? this.state.tags : [] } onTagsChange={ this.onTagsChange }/>
            <div className="tag-management"><NavLink to="/tagi">Zarządzaj tagami</NavLink></div>
          </div>
        )
      } else if (item.type === "products") {
        return (
          <div key={ i }>
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
            <Tags API={ this.props.API } tags={ this.state.productList !== "" ? this.state.productList : [] } onTagsChange={ this.onTagsChange }/>
            <div className="tag-management"><NavLink to="/produkty-finansowe">Zarządzaj produktami</NavLink></div>
          </div>
        )
      } else if (item.type === "additional_fields") {
        return (
          <div key={ i }>
            <AdditionalFields templates={ this.props.templates } template={ this.state.template } fields={ this.state.additional_fields } additionalFieldsChange={ this.additionalFieldsChange }/>
          </div>
        )
      } else if (item.type === "barem") {
        return (
          <div key={ i } className="title">
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
            <BaremList user={ this.props.user } API={ this.props.API } barems={ this.state.baremList !== "" ? this.state.baremList : [] } onBaremChange={ this.onBaremChange }/>
          </div>
        )
      } else if (item.type === "roles") {
        return (
          <div key={ i } className="title">
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
            <Roles user={ this.props.user } API={ this.props.API } name={ item.name } onRoleChange={ this.onRoleChange } value={ this.state[item.name] }/>
          </div>
        )
      } else if (item.type === "passwd") {
        return (
          <div key={ i } className="title">
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
            <input type="password" name={ item.name } value={ this.state[item.name] } onChange={ this.handlePassChange } data-parsley-pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$" required={ item.required ? true : false }/>
            <p className="input-desc">Powtórz hasło</p>
            <input type="password" name="repeat" value={ this.state.repeat } onChange={ this.handlePassChange } data-parsley-pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$" required={ item.required ? true : false }/>
            { this.state.passerr ? <p className="error">Hasła są różne!</p> : null }
          </div>
        )
      } else if (item.type === "countMin") {
        return (
          <div key={ i }>
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
            <div className="withbtn"><button onClick={ this.countMin.bind(null, item.name, item.from) }>Wylicz</button><input type="text" name={ item.name } value={ this.state[item.name] } onChange={ this.handleInputChange } required={ item.required ? true : false }/></div>
          </div>
        )
      } else if (item.type === "countMax") {
        return (
          <div key={ i }>
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
            <div className="withbtn"><button onClick={ this.countMax.bind(null, item.name, item.from) }>Wylicz</button><input type="text" name={ item.name } value={ this.state[item.name] } onChange={ this.handleInputChange } required={ item.required ? true : false }/></div>
          </div>
        )
      } else if (item.type === "steps") {
        let steps = []
        let min = parseInt(this.state[item.from]) || 0
        let max = parseInt(this.state[item.to]) || 0
        let step = parseInt(this.state[item.step]) || 0
        let count = ((max - min) / step)
        if (max > 0 && step > 0 && min < max) {
          for (var j = 0; j <= count; j++) {
            steps.push(j)
          }
        }
        return (
          <div key={ "steps"+i }>
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
            <select name={ item.name } onChange={ this.handleInputChange } value={ this.state[item.name] }>
              <option value="">Wybierz</option>
              { steps.map((item, k) => {
                return (
                  <option key={ k } value={ item }>{ item } - ({ item*step+min })</option>
                )
              })}
            </select>
          </div>
        )
      }
    })

    return (
      <section className="new">
        <div className="container">
    			<h1>{ this.props.data.name } - dodawanie</h1>
          <form className="fields" data-parsley-validate>
            { this.props.user.role === "admin" || this.props.user.role === "Moderator" ? <div className="add-top" onClick={ this.add }>Dodaj</div> : null }
            <div className="back-top" onClick={ () => { this.props.history.goBack() } }>Powrót</div>
            { fields }
            { this.props.user.role === "admin" || this.props.user.role === "Moderator" ? <div className="add-bottom" onClick={ this.add }>Dodaj</div> : null }
            <div className="back-bottom" onClick={ () => { this.props.history.goBack() } }>Powrót</div>
            { this.state.preloader ? <div className="preloader"><span></span></div> : null }
          </form>
	  	  </div>
      </section>
    )
  }
}
