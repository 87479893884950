import React from 'react'
import BaseComponent from './BaseComponent'
import _ from 'lodash'
import Modal from './Modal'

class Ifcomponent extends BaseComponent {
  constructor(props) {
    super(props)
    this.state = { key: "", value: "" }
    this._bind('deleteItem')
  }

  deleteItem(key) {
    let object = this.props.params
    console.log(key)
    delete object[key]
    this.props.updateIfObject(object)
  }

  render() {
    let items = []

    for (var key in this.props.params) {
      if (this.props.params.hasOwnProperty(key)) {
        items.push(<li key={ key }>{ key } : { this.props.params[key] }<span className="remove" onClick={ this.deleteItem.bind(null, key) }></span></li>)
      }
    }

    return (
      <div className="if-component">
        <ul>
          { items }
        </ul>
        <div>
          <div className="inputs">
            <input type="text" name="key" placeholder="key" value={ this.state.key } onChange={ (e) => { this.setState({ key: e.target.value }) } }/>
            <input type="text" name="value" placeholder="value" value={ this.state.value } onChange={ (e) => { this.setState({ value: e.target.value }) } }/>
          </div>
          <button onClick={ (e) => {
            e.preventDefault()
            if (this.state.key !== "" && this.state.value !== "") {
              let object = this.props.params
              object[this.state.key] = this.state.value
              this.props.updateIfObject(object)
              this.setState({ key: "", value: "" })
            }
          } }>Dodaj</button>
        </div>
      </div>
    )
  }
}

export default class extends BaseComponent {

  constructor(props) {
  	super(props)
  	this.state = { 
  		confirm: false, 
  		itemId: null, 
  		edit: false,
  		itemObject: {
	  		uuid: "",
  			name: "",
  			type: "",
        required: false,
        content: "",
        contentEn: "",
        group: "",
        field121: "",
        ebecsNoOfConsent: "",
        ebecsDocumentType: "",
        ebecsDocumentVersion: "",
        ifTrue: {},
        ifFalse: {}
  	  }
  	}
    
  	this._bind('delete', 'resetObject', 'closeEdit', 'setObject', 'openEdit', 'editObject', 'saveObject', 'closeConfirm')
  }

  delete(id) {
    this.setState({ preloader: true, confirm: false })
    let consents = this.props.consents
    //consents.splice(id, 1)
    if (consents[id].uuid !== "") {
      consents[id].trash = true
    }
    this.props.onChangeConsent(consents)
  }

  openEdit(id) {
    this.setState({ edit: true, itemId: id })
    if (id >= 0) {
      this.setObject(id)
    }
  }

  closeEdit() {
  	this.setState({ edit: false, itemId: null })
  	this.resetObject()
  }

  setObject(id) {
    let consent = this.props.consents[id]

    let item = {
      uuid: consent.uuid,
      name: consent.name,
      type: consent.type,
      required: consent.required,
      content: consent.content,
      contentEn: consent.contentEn,
      group: consent.group,
      field121: consent.field121,
      ebecsNoOfConsent: consent.ebecsNoOfConsent,
      ebecsDocumentType: consent.ebecsDocumentType,
      ebecsDocumentVersion: consent.ebecsDocumentVersion,
      ifTrue: consent.ifTrue.length === 0 ? {} : consent.ifTrue,
      ifFalse: consent.ifFalse.length === 0 ? {} : consent.ifFalse
    }

    this.setState({ itemObject: item })
  }

  resetObject() {
  	let item = {
  		uuid: "",
  		name: "",
  		type: "",
      required: false,
      content: "",
      contentEn: "",
      group: "",
      field121: "",
      ebecsNoOfConsent: "",
      ebecsDocumentType: "",
      ebecsDocumentVersion: "",
      ifTrue: {},
      ifFalse: {}
  	}

  	this.setState({ itemObject: item })
  }

  editObject(event) {
    const target = event.target
    let value = target.type === 'checkbox' ? target.checked : target.value
    let name = target.name

    let item = this.state.itemObject

    if (value === "false") {
      value = false
    } else if(value === "true") {
      value = true
    }

    item[name] = value

    this.setState({ itemObject: item })
  }

  saveObject() {
    let consents = this.props.consents
    if (this.state.itemId >= 0) {
      let singleConsent = consents[this.state.itemId]
      let consentIndex = this.state.itemId

      consents[consentIndex].uuid = this.state.itemObject.uuid
      consents[consentIndex].name = this.state.itemObject.name
      consents[consentIndex].type = this.state.itemObject.type
      consents[consentIndex].required = this.state.itemObject.required
      consents[consentIndex].content = this.state.itemObject.content
      consents[consentIndex].contentEn = this.state.itemObject.contentEn
      consents[consentIndex].group = this.state.itemObject.group
      consents[consentIndex].field121 = this.state.itemObject.field121
      consents[consentIndex].ebecsNoOfConsent = this.state.itemObject.ebecsNoOfConsent
      consents[consentIndex].ebecsDocumentType = this.state.itemObject.ebecsDocumentType
      consents[consentIndex].ebecsDocumentVersion = this.state.itemObject.ebecsDocumentVersion
      consents[consentIndex].ifTrue = this.state.itemObject.ifTrue
      consents[consentIndex].ifFalse = this.state.itemObject.ifFalse
    } else {
      consents.push(this.state.itemObject)
    }

    this.props.onChangeConsent(consents)
    this.closeEdit()
  }

  closeConfirm() {
    this.setState({ confirm: false, itemId: null })
  }

  render () {
    console.log(this.state.itemObject)

  	let edit = (
  		<div className="popup-inner">
  			<form className="fields" data-parsley-validate>
	            <div className="add-top" onClick={ this.saveObject }>OK</div>
	            <div className="back-top" onClick={ () => { this.closeEdit() } }>Powrót</div>
	            <div>
		            <h4>Nazwa</h4>
		            <input type="text" name="name" value={ this.state.itemObject.name } onChange={ this.editObject } required/>
  		        </div>
  		        <div>
  		            <h4>Typ</h4>
  		            <input type="text" name="type" value={ this.state.itemObject.type } onChange={ this.editObject } required/>
  		        </div>
  		        <div>
  		            <h4>Wymagane</h4>
  		            <select name="required" value={ this.state.itemObject.required } onChange={ this.editObject }>
                    <option value={ true }>TAK</option>
                    <option value={ false }>NIE</option>
                  </select>
              </div>
  		        <div>
  		            <h4>Treść</h4>
  		            <input type="text" name="content" value={ this.state.itemObject.content } onChange={ this.editObject } required/>
  		        </div>
  		        <div>
  		            <h4>Treść EN</h4>
  		            <input type="text" name="contentEn" value={ this.state.itemObject.contentEn } onChange={ this.editObject } required/>
  		        </div>
  		        <div>
  		            <h4>Grupa</h4>
  		            <input type="text" name="group" value={ this.state.itemObject.group } onChange={ this.editObject } required/>
  		        </div>
  		        <div>
  		            <h4>Pole 121</h4>
  		            <input type="text" name="field121" value={ this.state.itemObject.field121 } onChange={ this.editObject } required/>
  		        </div>
  		        <div>
  		            <h4>EbecsNoOfConsent</h4>
  		            <input type="text" name="ebecsNoOfConsent" value={ this.state.itemObject.ebecsNoOfConsent } onChange={ this.editObject } required/>
  		        </div>
  		        <div>
                  <h4>EbecsDocumentType</h4>
                  <input type="text" name="ebecsDocumentType" value={ this.state.itemObject.ebecsDocumentType } onChange={ this.editObject } required/>
              </div>
              <div>
                  <h4>EbecsDocumentVersion</h4>
                  <input type="text" name="ebecsDocumentVersion" value={ this.state.itemObject.ebecsDocumentVersion } onChange={ this.editObject } required/>
              </div>
              <div>
                <h4>ifTrue</h4>
                <Ifcomponent params={ this.state.itemObject.ifTrue } updateIfObject={ (item) => {
                  let itemObject = this.state.itemObject
                  itemObject.ifTrue = item
                  this.setState({ itemObject: itemObject })
                } }/>
              </div>
              <div>
                <h4>ifFalse</h4>
                <Ifcomponent params={ this.state.itemObject.ifFalse } updateIfObject={ (item) => {
                  let itemObject = this.state.itemObject
                  itemObject.ifFalse = item
                  this.setState({ itemObject: itemObject })
                } }/>
              </div>
	            <div className="add-bottom" onClick={ this.saveObject }>OK</div>
	            <div className="back-bottom" onClick={ () => { this.closeEdit() } }>Powrót</div>
	        </form>
  		</div>
  	)

  	const values = _.filter(this.props.consents, (item) => {
      if (!item.trash) {
        return item
      }
    }).map((item, i) => {
  		return (
  			<tr key={ i }>
  				<td>{ item.name }</td>
  				<td>{ item.type }</td>
  				<td>{ item.required ? "TAK" : "NIE" }</td>
  				<td>{ item.field121 }</td>
  				<td>{ item.ebecsNoOfConsent }</td>
  				{ this.props.user.role === "admin" || this.props.user.role === "Moderator" ? <td><span className="edit" onClick={ () => { this.openEdit(i) } }>Edytuj</span><br/><span className="delete" onClick={ () => { this.setState({ confirm: true, itemId: i }) } }>Kasuj</span></td> : null }
  			</tr>
  		)
  	})

    const Confirm = (
      <div className="confirm">
        <h4>Czy jesteś pewny?</h4>
        <div className="btn-holder">
          <a className="btn" onClick={ this.closeConfirm }><span>Nie</span></a>
          <a className="btn" onClick={ this.delete.bind(null, this.state.itemId) }><span>Tak</span></a>
        </div>
      </div>
    )

    return (
      <div className="barem-list">
      	<table style={{ width: "100%" }}>
      		<tbody>
    			  <tr>
    			    <th>Nazwa</th>
    			    <th>Typ</th> 
    			    <th>Wymagane</th>
    			    <th>Pole 121</th>
              <th>EbecsNoOfConsent</th>
    			    { this.props.user.role === "admin" || this.props.user.role === "Moderator" ? <th>Opcje</th> : null }
    			  </tr>
    			  { values }
    		  </tbody>
    		</table>
    		{ this.props.user.role === "admin" || this.props.user.role === "Moderator" ? <div className="btn-holder"><button className="btn" onClick={ (e) => { e.preventDefault(); this.openEdit() } }><span>Dodaj zgodę</span></button></div> : null }
        <Modal open={ this.state.edit } hidePopup={ this.closeEdit }>{ edit }</Modal>
        <Modal open={ this.state.confirm } hidePopup={ this.closeConfirm }>{ Confirm }</Modal>
      </div>
    )
  }
}
